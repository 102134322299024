import React, { useState, useEffect } from "react"
import Link from "gatsby-link"
import clearLocalStorage from "../../services/clearLocalStorage"

import * as styles from "./listObjects.module.scss"

import ArrowIcon from "./icon_arrow.svg"
import PdfIcon from "./icon_pdf.svg"
import isLoggedIn from "../../services/auth"

const ListObjects = ({ title, objects, isOnlyPDF }) => {

    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        isLoggedIn().then(loggedIn => {
            setLoggedIn(loggedIn)
            if (!loggedIn) {
                clearLocalStorage();
            }
        })
    }, [])


    if (isOnlyPDF) {
        objects = objects.map((item, key) => {
            if (item) {
                return (
                    <li
                        key={key}
                    >
                        <div>{item.title}</div>
                        <div>
                            {item.count} {item.type != "document" && item.type}
                        </div>
                        <div>
                            {item.duration && item.duration + " Minuten"}
                        </div>
                        <Link
                            target={'_blank'} to={loggedIn ? `${item.sourceUrl}` : `/checkout`}
                        >
                            {item.sourceUrl ? <PdfIcon /> : <ArrowIcon />}
                        </Link>
                    </li>
                )
            }
        })
    }

    else {
        objects = objects.map((item, key) => {
            if (item) {
                return (
                    <li
                        key={key}
                    >
                        <div>{item.title}</div>
                        <div>
                            {item.countVideos > 0 && item.countVideos + ` Video${item.countVideos !== 1 ? "s" : ""}`}
                        </div>
                        <div>
                            {item.duration > 0 && (item.duration + " Minuten")}
                        </div>
                        <Link
                            target={'_blank'} to={"/modules"} state={{ jumpTo: `${item.title}` }}
                        >
                            <ArrowIcon />
                        </Link>
                    </li>
                )
            }
        })
    }



    return (
        <div className={styles.listObjects}>
            <div className={`${styles.container}`}>
                <h2>{title}</h2>
                <ul>{objects}</ul>
            </div>
        </div>
    )
}

export default ListObjects
