import React, { useState } from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'


import * as styles from "./subscription.module.scss"
import * as btnStyles from "../buttons/buttons.module.scss"
import * as formStyles from "../../scss/form.module.scss"
import LoadingSpinner from "../loadingSpinner"


const Subscription = () => {
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState(false)
    const [success, setSuccess] = useState(false)
    const handleChange = event => {
        setValue(event.target.value)
    }

    const handleSubmit = e => {
        e.preventDefault();
        setHasErrors(false);
        setLoading(true);

        addToMailchimp(value) // listFields are optional if you are only capturing the email address.
            .then(data => {
                setLoading(false)

                if (data.result === 'error') {
                    setHasErrors(true)
                }else{
                    setSuccess(true)
                }

            })
            .catch(() => {
                setLoading(false)
            })
    }




    return (
        <div className={styles.subscription}>
            <div className={`container ${styles.container}`}>
                <img
                    src="https://detoxme.at/wp/wp-content/uploads/2021/03/Mockup_Rezeptbuch.png"
                    width="600"
                />
                <div className={`${styles.form} ${loading ? styles.loading : null}`}>
                    <h2 className="heading">Meine Detox-Rezeptideen für dich!</h2>
                    <p>Trage deine Mail-Adresse unten ins Feld ein und ich sende dir einige meiner besten Detox-Rezepte zu.</p>
                    {
                        !success ?
                            <form className={formStyles.form} action="" onSubmit={handleSubmit}>
                                <input
                                    type="email"
                                    value={value}
                                    onChange={handleChange}
                                    placeholder="E-Mail"
                                    className={`${formStyles.input} ${hasErrors ? formStyles.error : null}`}
                                />
                                <button onClick={handleSubmit} className={`${btnStyles.button} ${btnStyles.buttonBorder}`} style={{ width: 'auto', paddingLeft: (loading ? 10 : 20) }}>
                                    Rezepte erhalten
                                    {
                                        loading && <LoadingSpinner
                                            white
                                            style={{ width: 20, height: 20, float: 'left', position: 'relative', marginRight: 20 }}
                                        />
                                    }
                                </button>

                            </form>
                            :
                            <div className={styles.success}>Vielen Dank! Du hast dich erfolgreich registriert.<br/>Du erhältst in Kürze eine Mail mit der Bitte deine Registrierung zu bestätigen. (Bitte schaue auch in deinen Spam-Ordner)<br/><br/>Viel Spaß mit meinen Rezeptideen,<br/>deine Claudia.</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Subscription
