// extracted by mini-css-extract-plugin
export var form = "form-module--form--6b4-J";
export var input = "form-module--input--1TGWo";
export var error = "form-module--error--2aSIA";
export var inputError = "form-module--inputError--3LFmM";
export var passwordInput = "form-module--passwordInput--1OIsE";
export var twoItemsGroup = "form-module--twoItemsGroup--UlbcQ";
export var inputGroup = "form-module--inputGroup--KSNyd";
export var twoItemsRow = "form-module--twoItemsRow--rszH0";
export var inputRightIcon = "form-module--inputRightIcon--1z0Od";
export var checkbox = "form-module--checkbox--1Lwiz";
export var checkboxGroup = "form-module--checkboxGroup--3Sxpi";